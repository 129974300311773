<template>
  <div>
    <a-table
      :dataSource="table_data"
      :columns="table_columns"
      :pagination="pagination_props"
      @change="changeCurrentPage"
      :loading="loading_data"
    >
      <!-- Modal de filtro de input -->
      <template #filterDropdown="{ column, confirm, clearFilters }">
        <div style="padding: 8px">
          <a-input
            v-model:value="filter_values[column.key]"
            :placeholder="`Buscar por ${column.title}`"
            @pressEnter="
              handleSearch(column.dataIndex, filter_values[column.key], confirm)
            "
            style="width: 188px; margin-bottom: 8px; display: block"
          />

          <a-button
            type="primary"
            size="small"
            style="width: 90px; margin-right: 8px"
            @click="
              handleSearch(column.dataIndex, filter_values[column.key], confirm)
            "
          >
            <template #icon><search-outlined /></template>
            Buscar
          </a-button>

          <a-button
            size="small"
            style="width: 90px"
            @click="handleColumnReset(column.dataIndex, clearFilters)"
          >
            Limpiar
          </a-button>
        </div>
      </template>

      <!-- Filtros para estados -->
      <template #filterStates="{ confirm, column, clearFilters }">
        <div style="padding: 8px; width: 300px">
          <a-checkbox-group v-model:value="states_selection">
            <a-row>
              <a-col
                :span="24"
                v-for="(state, index) in states_options"
                :key="index"
              >
                <a-checkbox :value="state.value">{{ state.label }}</a-checkbox>
              </a-col>
            </a-row>
          </a-checkbox-group>

          <div
            class="mt-4 p-2"
            style="
              border-top: 1px solid #f0f0f0;
              display: flex;
              justify-content: space-between;
            "
          >
            <a-button
              type="primary"
              size="small"
              style="width: 90px; margin-right: 8px"
              @click="
                handleSearch(
                  column.dataIndex,
                  states_selection.join(','),
                  confirm
                )
              "
            >
              <template #icon><search-outlined /></template>
              Buscar
            </a-button>

            <a-button
              size="small"
              style="width: 90px"
              @click="handleColumnReset(column.dataIndex, clearFilters)"
            >
              Limpiar
            </a-button>
          </div>
        </div>
      </template>

      <!-- Modal de filtro de input -->
      <template #filterCompanies="{ column, confirm, clearFilters }">
        <div style="padding: 8px; width: 300px">
          <a-select
            v-model:value="selected_companies"
            :options="companies"
            mode="multiple"
            show-search
            :filterOption="true"
            optionFilterProp="label"
            placeholder="Buscar por Razon social"
            style="width: 100%"
          >
            <template #dropdownRender="{ menuNode: menu }">
              <v-nodes :vnodes="menu" />
              <div
                style="
                  border-top: 1px solid #f0f0f0;
                  display: flex;
                  justify-content: space-between;
                "
                class="p-2"
                @mousedown="(e) => e.preventDefault()"
              >
                <a-button
                  type="primary"
                  size="small"
                  style="width: 90px; margin-right: 8px"
                  @click="
                    handleSearch(
                      column.dataIndex,
                      selected_companies.join(','),
                      confirm
                    )
                  "
                >
                  Buscar
                </a-button>

                <a-button
                  size="small"
                  style="width: 90px"
                  @click="handleColumnReset(column.dataIndex, clearFilters)"
                >
                  Limpiar
                </a-button>
              </div>
            </template>
          </a-select>
          <div
            class="mt-4 p-2"
            style="
              border-top: 1px solid #f0f0f0;
              display: flex;
              justify-content: space-between;
            "
          >
            <a-button
              type="primary"
              size="small"
              style="width: 90px; margin-right: 8px"
              @click="
                handleSearch(
                  column.dataIndex,
                  selected_companies.join(','),
                  confirm
                )
              "
            >
              Buscar
            </a-button>

            <a-button
              size="small"
              style="width: 90px"
              @click="handleColumnReset(column.dataIndex, clearFilters)"
            >
              Limpiar
            </a-button>
          </div>
        </div>
      </template>

      <!-- Icono de filtrado de lupa -->
      <template #filterIcon="{ column }">
        <search-outlined
          :style="{
            color:
              searched_value[column.dataIndex] === undefined ||
              searched_value[column.dataIndex] === ''
                ? undefined
                : '#2521E5'
          }"
        />
      </template>

      <!-- Tag de los estados -->
      <template #tag="{ text: tag }">
        <tag :stateId="tag" />
      </template>

      <!-- Icono de filtrado para seleccion por checkbox -->
      <template #filterIconState="{ column }">
        <filter-outlined
          :style="{
            color:
              searched_value[column.dataIndex] === undefined ||
              searched_value[column.dataIndex] === ''
                ? undefined
                : '#2521E5'
          }"
        />
      </template>

      <!-- Tag para ver el detalle -->
      <template #details="{ text }">
        <a-tooltip :color="'#05045D'">
          <template #title>Ver más</template>
          <router-link :to="{ name: text.link, params: { id: text.id } }">
            <eye-outlined />
          </router-link>
        </a-tooltip>
      </template>
    </a-table>
  </div>
</template>

<script>
import { computed, onMounted, reactive, ref, watch, watchEffect } from 'vue';
import { useStore } from 'vuex';
import { useRoute, useRouter } from 'vue-router';
import {
  SearchOutlined,
  FilterOutlined,
  EyeOutlined
} from '@ant-design/icons-vue';
import {
  FETCH_GLOBAL_STOCK_QUERY,
  GET_GLOBAL_STOCK_TOTAL_PAGES,
  GET_GLOBAL_STOCK_ITEM_PER_PAGE,
  GET_GLOBAL_STOCK_CURRENT_PAGE,
  GET_GLOBAL_STOCK_ROWS_TYPE,
  GET_ALL_COMPANIES,
  FETCH_ALL_COMPANIES
} from '../../store/types';
import rutHelper from '../../utils/Ruts';
import tag from '../Tags.vue';

export default {
  components: {
    'search-outlined': SearchOutlined,
    'filter-outlined': FilterOutlined,
    'eye-outlined': EyeOutlined,
    tag,
    VNodes: (_, { attrs }) => {
      return attrs.vnodes;
    }
  },
  name: 'TableResults',
  props: {
    type: {
      default: 'tickets',
      validator(value) {
        return ['tickets', 'cases'].includes(value);
      }
    },
    dates: Object
  },
  setup(props) {
    // * Router/Route
    const route = useRoute();
    const router = useRouter();

    // * Vuex
    const store = useStore();

    // * Tipo de tabla, si es para resultados de tickets o casos
    const table_type = computed(() => props.type);

    // * Tabla
    const loading_data = ref(false); // Efecto de carga en la tabla

    const total = computed(() => {
      return store.getters[GET_GLOBAL_STOCK_TOTAL_PAGES](table_type.value);
    });

    // Items por pagina
    const items_per_page = computed(() => {
      return store.getters[GET_GLOBAL_STOCK_ITEM_PER_PAGE](table_type.value);
    });

    // Pagina en la que nos encontramos
    const current_page = computed(() => {
      return store.getters[GET_GLOBAL_STOCK_CURRENT_PAGE](table_type.value);
    });

    const pagination_props = reactive({
      total,
      pageSize: items_per_page,
      current: current_page
    });

    const table_data = computed(() => {
      return store.getters[GET_GLOBAL_STOCK_ROWS_TYPE](table_type.value)?.map(
        (obj) => {
          return {
            id: obj.id,
            key: obj.id,
            holding: obj.holding,
            companyId: obj.company.name,
            companyRut: rutHelper.formatRut(obj.company.rut),
            subHolding: obj.subHolding,
            employeeName: obj.employee.name,
            employeeRut: rutHelper.formatRut(obj.employee.rut),
            state: obj.state.id,
            details: {
              id: obj.id,
              link: getUrlByTypeAndId(obj.type.id)
            }
          };
        }
      );
    });

    const table_columns = [
      {
        title: 'Id',
        dataIndex: 'id',
        key: 'id'
      },
      {
        title: 'Holding',
        dataIndex: 'holding',
        key: 'holding',
        slots: {
          filterDropdown: 'filterDropdown',
          filterIcon: 'filterIcon'
        }
      },
      {
        title: 'Subholding',
        dataIndex: 'subHolding',
        key: 'subHolding',
        slots: {
          filterDropdown: 'filterDropdown',
          filterIcon: 'filterIcon'
        }
      },
      {
        title: 'Razon social',
        dataIndex: 'companyId',
        key: 'companyId',
        slots: {
          filterDropdown: 'filterCompanies',
          filterIcon: 'filterIcon'
        }
      },
      {
        title: 'RUT empresa',
        dataIndex: 'companyRut',
        key: 'companyRut',
        slots: {
          filterDropdown: 'filterDropdown',
          filterIcon: 'filterIcon'
        }
      },
      {
        title: 'Nombre',
        dataIndex: 'employeeName',
        key: 'employeeName',
        slots: {
          filterDropdown: 'filterDropdown',
          filterIcon: 'filterIcon'
        }
      },
      {
        title: 'RUT empleado',
        dataIndex: 'employeeRut',
        key: 'employeeRut',
        slots: {
          filterDropdown: 'filterDropdown',
          filterIcon: 'filterIcon'
        }
      },
      {
        title: 'Estado',
        dataIndex: 'state',
        key: 'state',
        slots: {
          customRender: 'tag',
          filterDropdown: 'filterStates',
          filterIcon: 'filterIconState'
        }
      },
      {
        title: 'Opciones',
        dataIndex: 'details',
        key: 'details',
        slots: {
          customRender: 'details'
        }
      }
    ];

    // * busquedas y cargas para los filtros
    const filter_values = ref({}); // Objeto que guarda los valores de los filtros
    const searched_value = ref({}); // Objeto que guarda los valores de los cuales se hace la busqueda por filtro

    // Cuando se use un filtro de hace una redireccion de la pagina
    const filterRedirectionNewParam = (key, param) => {
      searched_value.value[key] = param;

      if (key != 'page') {
        searched_value.value['page'] = 1;
      }

      router.replace({
        name: 'GlobalStock',
        query: searched_value.value,
        hash: route.hash != undefined || route.hash != '' ? route.hash : ''
      });
    };

    const changeCurrentPage = (page) => {
      filterRedirectionNewParam('page', page.current);
    };

    // Busqueda por columna
    const handleSearch = (column, value, confirm) => {
      if (value != '') {
        // Hago la redireccion con el nuevo valor
        filterRedirectionNewParam(column, value);
        // Hago que se cierre el mini modal de busqueda
        confirm();
      }
    };

    // Redireccion reseteando o sacando un parametro
    const filterRedirectionResetColumn = (key) => {
      delete searched_value.value[key];

      router.replace({
        name: 'GlobalStock',
        query: searched_value.value,
        hash: route.hash != undefined || route.hash != '' ? route.hash : ''
      });
    };

    // Limpiar columna
    const handleColumnReset = (column, resetConfirm) => {
      filterRedirectionResetColumn(column);
      resetConfirm();
      delete filter_values.value[column];

      if (column === 'state') {
        states_selection.value = [];
      }

      if (column === 'companyId') {
        selected_companies.value = [];
      }
    };

    // Seleccion de estados
    const states_selection = ref([]);
    const states_options = ref([
      {
        label: 'Creado',
        value: 1
      },
      {
        label: 'Pendiente',
        value: 2
      },
      {
        label: 'En revisión corredora',
        value: 7
      },
      {
        label: 'En revisión aseguradora',
        value: 8
      },
      {
        label: 'Ejecutado',
        value: 3
      },
      {
        label: 'Finalizado',
        value: 5
      },
      {
        label: 'Cancelado',
        value: 4
      }
    ]);

    const getCompaniesList = async () => {
      try {
        store.dispatch(FETCH_ALL_COMPANIES);
      } catch (error) {
        console.log(error);
      }
    };
    const selected_companies = ref([]);
    const companies = computed(() => {
      return store.getters[GET_ALL_COMPANIES].map((obj) => {
        return {
          value: obj.id,
          label: obj.businessName
        };
      });
    });

    const getUrlByTypeAndId = (type_id) => {
      let final = '';

      if (props.type === 'tickets') {
        switch (type_id) {
          case 1:
            final = 'AltasDetalle';
            break;
          case 2:
            final = 'BajaDetail';
            break;
          default:
            final = 'DetalleModificaciones';
            break;
        }
      } else {
        switch (type_id) {
          case 1:
            final = 'DetalleCaso';
            break;
          case 2:
            final = 'DetalleRechazo';
            break;
          default:
            final = 'DetalleFueraTiempo';
            break;
        }
      }
      return final;
    };

    // * Filtro de fecha desde afuera
    watch(
      () => props.dates,
      (new_val) => {
        filterRedirectionNewParam('startDate', new_val[0]);
        filterRedirectionNewParam('endDate', new_val[1]);
      }
    );

    // * Apenas se cargue el componente o se haga un filtro/paginacion se obtendra la data
    watchEffect(async () => {
      // Cuando traigo la data dejo cargando la tabla
      loading_data.value = true;

      try {
        await store.dispatch(FETCH_GLOBAL_STOCK_QUERY, {
          type: table_type.value,
          query: route.fullPath.split('?')[1]
        });
      } catch (error) {
        console.log(error);
      }

      // Saco la carga de la tabla
      loading_data.value = false;
    });

    // * Cuando este montado el componente voy a obtener los parametros de la url para
    // * pasarlo a los filtros
    onMounted(() => {
      // Listado de companies
      getCompaniesList();

      // parametros de la url
      const key_params = Object.keys(route.query);

      key_params.forEach((key) => {
        filter_values.value[key] = route.query[key]; // Le paso valor al los inputs para que muestre que es lo que busco anteriormente
        searched_value.value[key] = route.query[key]; // De los parametros de la url, los guardo en un objeto, este lo uso despues para poder hacer otro filtro junto con los valores anteriores guardados

        // en caso de que el filtro que venga sea 'State', voy a dejar selecciadas las casillas checkbox
        if (key === 'state') {
          states_selection.value.push(
            ...route.query[key].split(',').map((e) => parseInt(e))
          );
        }

        // en caso de que el filtro que venga sea 'companyId', voy a dejar selecciadas las casillas checkbox
        if (key === 'companyId') {
          selected_companies.value.push(
            ...route.query[key].split(',').map((e) => parseInt(e))
          );
        }
      });
    });

    return {
      table_type,
      loading_data,
      pagination_props,
      table_data,
      table_columns,
      searched_value,
      filter_values,
      states_selection,
      states_options,
      selected_companies,
      companies,
      handleSearch,
      handleColumnReset,
      changeCurrentPage
    };
  }
};
</script>
